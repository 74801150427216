.footer {
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  @include background-responsive(
    "../assets/images/galaxy-background",
    "jpg",
    (1600, 1366, 1024, 768, 640, 375)
  );

  &__column {
    margin-bottom: rem-from-px(50);

    @include from($tablet) {
      margin-bottom: 0;
    }
  }

  &__container {
    padding: rem-from-px(50) rem-from-px(30);
    color: $white;
    text-align: center;

    @include from($tablet) {
      text-align: left;
    }
  }

  &__link {
    color: $white;
    font-weight: $weight-light;
    font-size: rem-from-px(18);
    line-height: 2.22;
    transition: color 0.3s;

    &:hover {
      color: rgba($white, 0.6);
    }
  }

  &__logo {
    width: rem-from-px(42);
  }

  &__title {
    margin-bottom: rem-from-px(23);
    color: $white;
    font-size: rem-from-px(22);
  }

  // to target logo flex item
  .column:first-child {
    display: inline-flex;
  }

  p + p {
    margin-top: 1em
  }
}
