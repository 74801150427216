.contributing {
  @include from($tablet) {
    padding-bottom: rem-from-px(90);
  }

  &__block {
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    @include background-responsive(
      "../assets/images/contribute-background",
      "jpg",
      (1600, 1366, 1024, 768, 640, 375)
    );
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: rem-from-px(960);
    max-height: rem-from-px(272);
    margin: 0 auto;
    padding: rem-from-px(35) rem-from-px(15);

    @include from($tablet) {
      flex-direction: row;
      height: rem-from-px(126);
    }
  }

  &__text {
    flex-grow: 1;
    margin-bottom: rem-from-px(30);
    color: $white;
    font-weight: $weight-light;
    font-size: rem-from-px(26);
    text-align: center;

    @include from($tablet) {
      margin: initial;
      text-align: left;
    }
  }
}
