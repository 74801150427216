.header {
  $root: &;
  margin: rem-from-px(38) rem-from-px(10);

  @include from($tablet) {
    margin: rem-from-px(35) rem-from-px(10);
  }

  // Branding
  &__branding {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;

    @include from($tablet) {
      flex-direction: row;
      margin: 0;
    }

    &Logo {
      width: rem-from-px(43);
      height: rem-from-px(37);
      margin: 0 0 rem-from-px(14) 0;

      @include from($tablet) {
        width: rem-from-px(71);
        height: rem-from-px(61);
        margin: 0 rem-from-px(22) 0 0;
      }
    }

    &NameAuthor {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      @include from($tablet) {
        flex-direction: row;
      }
    }

    &Name {
      width: rem-from-px(51);
      height: rem-from-px(20);
      margin: 0 0 rem-from-px(3) 0;

      @include from($tablet) {
        width: rem-from-px(89);
        height: rem-from-px(32);
        margin: 0 rem-from-px(12) 0 0;
      }
    }

    &Author {
      color: $white;
      font-weight: $weight-light;
      font-size: rem-from-px(10);

      @include from($tablet) {
        font-size: rem-from-px(16);
      }
    }
  }

  // Input checkbox to control sidebar on mobile
  &__button {
    display: none;

    // When mobile navigation is opened
    &:checked {
      // Opened: Navigation
      ~ #{$root}__navigation {
        transform: translateX(0);
      }

      ~ #{$root}__icon {
        position: fixed;

        #{$root}__iconLines {
          background: transparent;

          &:before,
          &:after {
            top: 0;
          }

          &:before {
            transform: rotate(45deg);
          }

          &:after {
            transform: rotate(-45deg);
          }
        }
      }
    }
  }

  // Hamburger icon
  &__icon {
    position: absolute;
    top: rem-from-px(30);
    left: rem-from-px(30);
    z-index: 900;
    margin: 0;
    padding: rem-from-px(8) 0;
    color: $white;
    cursor: pointer;
    user-select: none;

    @include from($tablet) {
      display: none;
    }

    &Lines {
      position: relative;
      width: rem-from-px(24);
      height: rem-from-px(2);
      transition: background-color 0.2s ease-out;

      &:before,
      &:after {
        position: absolute;
        width: 100%;
        height: 100%;
        transition: all 0.2s ease-out;
        content: "";
      }

      &,
      &:before,
      &:after {
        display: block;
        background-color: $white;
      }

      &:before {
        top: rem-from-px(-8);
      }

      &:after {
        top: rem-from-px(8);
      }
    }
  }

  // Main container
  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: rem-from-px(60);
  }

  // Navigation
  &__navigation {
    $nav: &;

    display: flex;

    &Item {
      margin: 0 rem-from-px(50) 0 0;

      &:last-child {
        margin: 0;
      }
    }

    &Link {
      color: $white;
      font-weight: $weight-normal;
      font-size: rem-from-px(16);
      letter-spacing: rem-from-px(1.24);
      text-transform: uppercase;
      text-decoration: none;
      border-color: transparent;
      border-style: solid;
      border-width: 0 0 rem-from-px(2) 0;
      transition: color 0.3s, border-color 0.3s;

      svg {
        width: rem-from-px(36);
        height: rem-from-px(33);
        transition: opacity 0.3s;
      }

      &:focus {
        border-color: $color-razzle-rose;
        outline: 0;
      }

      &:hover {
        color: rgba($white, 0.6);
        text-decoration: none;

        svg {
          opacity: 0.6;
        }
      }
    }

    &List {
      display: flex;
      align-items: center;
      margin: 0;
      padding: 0;
      list-style: none;
    }

    &.-isSidebar {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      z-index: 30;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: rem-from-px(260);
      background: rgba($color-haiti, 0.9);
      transform: translateX(-150vw);
      transition: transform 0.3s;

      #{$nav} {
        &Item {
          margin: 0 0 rem-from-px(50) 0;

          &:last-child {
            margin: 0;
          }
        }

        &Link {
          font-size: rem-from-px(20);

          svg {
            width: rem-from-px(45);
            height: rem-from-px(42);
          }
        }

        &List {
          flex-direction: column;
        }
      }
    }
  }
}
