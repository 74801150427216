.compatibleServices {
  margin-top: rem-from-px(60);

  @include from($tablet) {
    margin-top: rem-from-px(35);
  }

  .column {
    padding: rem-from-px(20);
  }

  &__item {
    border-color: transparent;
    border-style: solid;
    border-width: 0 0 rem-from-px(3) 0;
    transition: border-color 0.3s;

    img {
      filter: grayscale(1);
      transition: filter 0.3s, transform 1s;
      max-height: rem-from-px(80);
      max-width: 80%;
    }

    &:hover img {
      transform: translateY(rem-from-px(-10));
      filter: grayscale(0);
    }
  }

  .slider__slide img {
    height: rem-from-px(76);
  }
}
