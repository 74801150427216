.link {
  color: $grey-dark;
  text-decoration: none;
  border-bottom: rem-from-px(2) solid $color-razzle-rose;
  transition: color 0.3s;

  &:hover {
    color: $color-razzle-rose;
  }
}
