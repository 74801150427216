.cta {
  display: block;
  width: 100%;
  max-width: rem-from-px(315);
  min-height: rem-from-px(50);
  margin: 0 auto;
  padding: rem-from-px(9) rem-from-px(46);
  color: $white;
  font-size: rem-from-px(26);
  line-height: rem-from-px(31);
  text-align: center;
  background-color: $color-honey-flower;
  border: 0;
  border-radius: rem-from-px(200); // High value to maintain circular corners regardless of height
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;

  @include from($tablet) {
    display: inline-block;
    width: auto;
    max-width: initial;
    margin: initial;
  }

  @include mobile {
    &.-mt {
      margin-top: rem-from-px(50);
    }
  }

  &:focus {
    outline: 0;
    box-shadow: 0 0 rem-from-px(10) rem-from-px(2) $color-razzle-rose;
  }

  &:hover {
    color: $white;
    background-color: $color-razzle-rose;
  }

  &.-inverse:not(:hover) {
    color: $color-honey-flower;
    background-color: $white;
  }

  &.-small {
    font-size: rem-from-px(22);
  }

  &.-on-pink {
    &:focus {
      box-shadow: 0 0 rem-from-px(10) rem-from-px(2) $color-honey-flower;
    }

    &:hover {
      background-color: $color-honey-flower;
    }
  }
}
