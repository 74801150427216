.slider {
  $root: &;

  &.-displayDots #{$root}__dots {
    display: block;
  }

  &__dots {
    display: none;
    margin-top: rem-from-px(60);

    &Wrap {
      display: flex;
      justify-content: center;
      list-style: none;
    }

    &Item {
      position: relative;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: rem-from-px(12);
      height: rem-from-px(12);
      margin: 0 rem-from-px(5);
      padding: 0;
      text-decoration: none;
      background-color: #d8d8d8;
      border: 0;
      border-radius: 50%;
      outline: 0;
      cursor: pointer;
      transition: background-color 0.3s, box-shadow 0.6s;
      -webkit-appearance: none;

      &.-isActive {
        background-color: $color-astronaut;
      }
    }
  }

  &__slide {
    position: relative;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-right: rem-from-px(50);
    scroll-snap-align: start;
  }

  &__track {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none; // to hide scrollbar on Firefox

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
