.card {
  padding: rem-from-px(30);
  background: $white;
  border-radius: rem-from-px(10);
  box-shadow: 0 rem-from-px(2) rem-from-px(4) 0 rgba($grey-lighter, 0.5);

  @include from($tablet) {
    padding: rem-from-px(40) rem-from-px(75);
  }

  .columns {
    --columnGap: 2rem;

    @include from($fullhd) {
      --columnGap: 3.5rem;
    }
  }

  .title {
    margin-bottom: rem-from-px(35);
  }
}
