.title {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include from($tablet) {
    flex-direction: row;
    justify-content: center;
  }

  &__icon {
    display: block;
    width: rem-from-px(48);
    height: rem-from-px(30);
    margin: 0 0 rem-from-px(20) 0;

    @include from($tablet) {
      margin: 0 rem-from-px(22) 0 0;
    }
  }

  &__text {
    color: $color-astronaut;
    font-size: rem-from-px(26);

    @include from($tablet) {
      font-size: rem-from-px(32);
    }
  }
}
