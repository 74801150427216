.catchphrase {
  margin-bottom: rem-from-px(25);
  color: $color-astronaut;
  font-size: rem-from-px(26);
  text-align: center;

  @include from($tablet) {
    margin: rem-from-px(32) 0 rem-from-px(15) 0;
  }

  @include mobile {
    br {
      display: block;
      margin-bottom: rem-from-px(20);
      content: "";
    }
  }

  &.-light {
    color: $grey-dark;
    font-weight: $weight-light;
  }
}
