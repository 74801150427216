.list {
  margin: rem-from-px(25) 0;

  &__item {
    display: flex;
    align-items: center;
    margin-bottom: rem-from-px(40);

    @include from($tablet) {
      margin-bottom: rem-from-px(50);
    }

    &:last-child {
      margin: 0;
    }

    &Icon {
      $list-icon-width: rem-from-px(18);

      flex: 0 0 $list-icon-width;
      width: $list-icon-width;
      height: $list-icon-width;
      margin-right: rem-from-px(22);
    }

    &Text {
      color: $grey;
      font-weight: $weight-light;
      font-size: rem-from-px(22);
    }
  }
}
