@use "sass:math";

@function rem-from-px($pxValue) {
  @return #{math.div($pxValue, 16)}rem;
}

.container {
  &.-initialPosition {
    position: initial !important; // stylelint-disable declaration-no-important
  }
}
