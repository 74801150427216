@font-face {
  font-display: swap;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 300;
  src: url("light.4471dd5d.woff2") format("woff2"), url("light.36d302df.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 400;
  src: url("regular.45e2a1c3.woff2") format("woff2"), url("regular.2f80b85c.woff") format("woff");
}

svg, .svg {
  max-width: 100%;
  max-height: 100%;
}

.container.-initialPosition {
  position: initial !important;
}

.is-block {
  display: block !important;
}

@media screen and (max-width: 768px) {
  .is-block-mobile {
    display: block !important;
  }
}

@media screen and (min-width: 769px), print {
  .is-block-tablet {
    display: block !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .is-block-tablet-only {
    display: block !important;
  }
}

@media screen and (max-width: 1023px) {
  .is-block-touch {
    display: block !important;
  }
}

@media screen and (min-width: 1024px) {
  .is-block-desktop {
    display: block !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .is-block-desktop-only {
    display: block !important;
  }
}

@media screen and (min-width: 1216px) {
  .is-block-widescreen {
    display: block !important;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .is-block-widescreen-only {
    display: block !important;
  }
}

@media screen and (min-width: 1408px) {
  .is-block-fullhd {
    display: block !important;
  }
}

.is-flex {
  display: flex !important;
}

@media screen and (max-width: 768px) {
  .is-flex-mobile {
    display: flex !important;
  }
}

@media screen and (min-width: 769px), print {
  .is-flex-tablet {
    display: flex !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .is-flex-tablet-only {
    display: flex !important;
  }
}

@media screen and (max-width: 1023px) {
  .is-flex-touch {
    display: flex !important;
  }
}

@media screen and (min-width: 1024px) {
  .is-flex-desktop {
    display: flex !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .is-flex-desktop-only {
    display: flex !important;
  }
}

@media screen and (min-width: 1216px) {
  .is-flex-widescreen {
    display: flex !important;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .is-flex-widescreen-only {
    display: flex !important;
  }
}

@media screen and (min-width: 1408px) {
  .is-flex-fullhd {
    display: flex !important;
  }
}

.is-inline {
  display: inline !important;
}

@media screen and (max-width: 768px) {
  .is-inline-mobile {
    display: inline !important;
  }
}

@media screen and (min-width: 769px), print {
  .is-inline-tablet {
    display: inline !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .is-inline-tablet-only {
    display: inline !important;
  }
}

@media screen and (max-width: 1023px) {
  .is-inline-touch {
    display: inline !important;
  }
}

@media screen and (min-width: 1024px) {
  .is-inline-desktop {
    display: inline !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .is-inline-desktop-only {
    display: inline !important;
  }
}

@media screen and (min-width: 1216px) {
  .is-inline-widescreen {
    display: inline !important;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .is-inline-widescreen-only {
    display: inline !important;
  }
}

@media screen and (min-width: 1408px) {
  .is-inline-fullhd {
    display: inline !important;
  }
}

.is-inline-block {
  display: inline-block !important;
}

@media screen and (max-width: 768px) {
  .is-inline-block-mobile {
    display: inline-block !important;
  }
}

@media screen and (min-width: 769px), print {
  .is-inline-block-tablet {
    display: inline-block !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .is-inline-block-tablet-only {
    display: inline-block !important;
  }
}

@media screen and (max-width: 1023px) {
  .is-inline-block-touch {
    display: inline-block !important;
  }
}

@media screen and (min-width: 1024px) {
  .is-inline-block-desktop {
    display: inline-block !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .is-inline-block-desktop-only {
    display: inline-block !important;
  }
}

@media screen and (min-width: 1216px) {
  .is-inline-block-widescreen {
    display: inline-block !important;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .is-inline-block-widescreen-only {
    display: inline-block !important;
  }
}

@media screen and (min-width: 1408px) {
  .is-inline-block-fullhd {
    display: inline-block !important;
  }
}

.is-inline-flex {
  display: inline-flex !important;
}

@media screen and (max-width: 768px) {
  .is-inline-flex-mobile {
    display: inline-flex !important;
  }
}

@media screen and (min-width: 769px), print {
  .is-inline-flex-tablet {
    display: inline-flex !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .is-inline-flex-tablet-only {
    display: inline-flex !important;
  }
}

@media screen and (max-width: 1023px) {
  .is-inline-flex-touch {
    display: inline-flex !important;
  }
}

@media screen and (min-width: 1024px) {
  .is-inline-flex-desktop {
    display: inline-flex !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .is-inline-flex-desktop-only {
    display: inline-flex !important;
  }
}

@media screen and (min-width: 1216px) {
  .is-inline-flex-widescreen {
    display: inline-flex !important;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .is-inline-flex-widescreen-only {
    display: inline-flex !important;
  }
}

@media screen and (min-width: 1408px) {
  .is-inline-flex-fullhd {
    display: inline-flex !important;
  }
}

.is-hidden {
  display: none !important;
}

.is-sr-only {
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: none !important;
  width: .01em !important;
  height: .01em !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

@media screen and (max-width: 768px) {
  .is-hidden-mobile {
    display: none !important;
  }
}

@media screen and (min-width: 769px), print {
  .is-hidden-tablet {
    display: none !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .is-hidden-tablet-only {
    display: none !important;
  }
}

@media screen and (max-width: 1023px) {
  .is-hidden-touch {
    display: none !important;
  }
}

@media screen and (min-width: 1024px) {
  .is-hidden-desktop {
    display: none !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .is-hidden-desktop-only {
    display: none !important;
  }
}

@media screen and (min-width: 1216px) {
  .is-hidden-widescreen {
    display: none !important;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .is-hidden-widescreen-only {
    display: none !important;
  }
}

@media screen and (min-width: 1408px) {
  .is-hidden-fullhd {
    display: none !important;
  }
}

.is-invisible {
  visibility: hidden !important;
}

@media screen and (max-width: 768px) {
  .is-invisible-mobile {
    visibility: hidden !important;
  }
}

@media screen and (min-width: 769px), print {
  .is-invisible-tablet {
    visibility: hidden !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .is-invisible-tablet-only {
    visibility: hidden !important;
  }
}

@media screen and (max-width: 1023px) {
  .is-invisible-touch {
    visibility: hidden !important;
  }
}

@media screen and (min-width: 1024px) {
  .is-invisible-desktop {
    visibility: hidden !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .is-invisible-desktop-only {
    visibility: hidden !important;
  }
}

@media screen and (min-width: 1216px) {
  .is-invisible-widescreen {
    visibility: hidden !important;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .is-invisible-widescreen-only {
    visibility: hidden !important;
  }
}

@media screen and (min-width: 1408px) {
  .is-invisible-fullhd {
    visibility: hidden !important;
  }
}

.is-size-1 {
  font-size: 3rem !important;
}

.is-size-2 {
  font-size: 2.5rem !important;
}

.is-size-3 {
  font-size: 2rem !important;
}

.is-size-4 {
  font-size: 1.5rem !important;
}

.is-size-5 {
  font-size: 1.25rem !important;
}

.is-size-6 {
  font-size: 1rem !important;
}

.is-size-7 {
  font-size: .75rem !important;
}

@media screen and (max-width: 768px) {
  .is-size-1-mobile {
    font-size: 3rem !important;
  }

  .is-size-2-mobile {
    font-size: 2.5rem !important;
  }

  .is-size-3-mobile {
    font-size: 2rem !important;
  }

  .is-size-4-mobile {
    font-size: 1.5rem !important;
  }

  .is-size-5-mobile {
    font-size: 1.25rem !important;
  }

  .is-size-6-mobile {
    font-size: 1rem !important;
  }

  .is-size-7-mobile {
    font-size: .75rem !important;
  }
}

@media screen and (min-width: 769px), print {
  .is-size-1-tablet {
    font-size: 3rem !important;
  }

  .is-size-2-tablet {
    font-size: 2.5rem !important;
  }

  .is-size-3-tablet {
    font-size: 2rem !important;
  }

  .is-size-4-tablet {
    font-size: 1.5rem !important;
  }

  .is-size-5-tablet {
    font-size: 1.25rem !important;
  }

  .is-size-6-tablet {
    font-size: 1rem !important;
  }

  .is-size-7-tablet {
    font-size: .75rem !important;
  }
}

@media screen and (max-width: 1023px) {
  .is-size-1-touch {
    font-size: 3rem !important;
  }

  .is-size-2-touch {
    font-size: 2.5rem !important;
  }

  .is-size-3-touch {
    font-size: 2rem !important;
  }

  .is-size-4-touch {
    font-size: 1.5rem !important;
  }

  .is-size-5-touch {
    font-size: 1.25rem !important;
  }

  .is-size-6-touch {
    font-size: 1rem !important;
  }

  .is-size-7-touch {
    font-size: .75rem !important;
  }
}

@media screen and (min-width: 1024px) {
  .is-size-1-desktop {
    font-size: 3rem !important;
  }

  .is-size-2-desktop {
    font-size: 2.5rem !important;
  }

  .is-size-3-desktop {
    font-size: 2rem !important;
  }

  .is-size-4-desktop {
    font-size: 1.5rem !important;
  }

  .is-size-5-desktop {
    font-size: 1.25rem !important;
  }

  .is-size-6-desktop {
    font-size: 1rem !important;
  }

  .is-size-7-desktop {
    font-size: .75rem !important;
  }
}

@media screen and (min-width: 1216px) {
  .is-size-1-widescreen {
    font-size: 3rem !important;
  }

  .is-size-2-widescreen {
    font-size: 2.5rem !important;
  }

  .is-size-3-widescreen {
    font-size: 2rem !important;
  }

  .is-size-4-widescreen {
    font-size: 1.5rem !important;
  }

  .is-size-5-widescreen {
    font-size: 1.25rem !important;
  }

  .is-size-6-widescreen {
    font-size: 1rem !important;
  }

  .is-size-7-widescreen {
    font-size: .75rem !important;
  }
}

@media screen and (min-width: 1408px) {
  .is-size-1-fullhd {
    font-size: 3rem !important;
  }

  .is-size-2-fullhd {
    font-size: 2.5rem !important;
  }

  .is-size-3-fullhd {
    font-size: 2rem !important;
  }

  .is-size-4-fullhd {
    font-size: 1.5rem !important;
  }

  .is-size-5-fullhd {
    font-size: 1.25rem !important;
  }

  .is-size-6-fullhd {
    font-size: 1rem !important;
  }

  .is-size-7-fullhd {
    font-size: .75rem !important;
  }
}

.has-text-centered {
  text-align: center !important;
}

.has-text-justified {
  text-align: justify !important;
}

.has-text-left {
  text-align: left !important;
}

.has-text-right {
  text-align: right !important;
}

@media screen and (max-width: 768px) {
  .has-text-centered-mobile {
    text-align: center !important;
  }
}

@media screen and (min-width: 769px), print {
  .has-text-centered-tablet {
    text-align: center !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .has-text-centered-tablet-only {
    text-align: center !important;
  }
}

@media screen and (max-width: 1023px) {
  .has-text-centered-touch {
    text-align: center !important;
  }
}

@media screen and (min-width: 1024px) {
  .has-text-centered-desktop {
    text-align: center !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .has-text-centered-desktop-only {
    text-align: center !important;
  }
}

@media screen and (min-width: 1216px) {
  .has-text-centered-widescreen {
    text-align: center !important;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .has-text-centered-widescreen-only {
    text-align: center !important;
  }
}

@media screen and (min-width: 1408px) {
  .has-text-centered-fullhd {
    text-align: center !important;
  }
}

@media screen and (max-width: 768px) {
  .has-text-justified-mobile {
    text-align: justify !important;
  }
}

@media screen and (min-width: 769px), print {
  .has-text-justified-tablet {
    text-align: justify !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .has-text-justified-tablet-only {
    text-align: justify !important;
  }
}

@media screen and (max-width: 1023px) {
  .has-text-justified-touch {
    text-align: justify !important;
  }
}

@media screen and (min-width: 1024px) {
  .has-text-justified-desktop {
    text-align: justify !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .has-text-justified-desktop-only {
    text-align: justify !important;
  }
}

@media screen and (min-width: 1216px) {
  .has-text-justified-widescreen {
    text-align: justify !important;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .has-text-justified-widescreen-only {
    text-align: justify !important;
  }
}

@media screen and (min-width: 1408px) {
  .has-text-justified-fullhd {
    text-align: justify !important;
  }
}

@media screen and (max-width: 768px) {
  .has-text-left-mobile {
    text-align: left !important;
  }
}

@media screen and (min-width: 769px), print {
  .has-text-left-tablet {
    text-align: left !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .has-text-left-tablet-only {
    text-align: left !important;
  }
}

@media screen and (max-width: 1023px) {
  .has-text-left-touch {
    text-align: left !important;
  }
}

@media screen and (min-width: 1024px) {
  .has-text-left-desktop {
    text-align: left !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .has-text-left-desktop-only {
    text-align: left !important;
  }
}

@media screen and (min-width: 1216px) {
  .has-text-left-widescreen {
    text-align: left !important;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .has-text-left-widescreen-only {
    text-align: left !important;
  }
}

@media screen and (min-width: 1408px) {
  .has-text-left-fullhd {
    text-align: left !important;
  }
}

@media screen and (max-width: 768px) {
  .has-text-right-mobile {
    text-align: right !important;
  }
}

@media screen and (min-width: 769px), print {
  .has-text-right-tablet {
    text-align: right !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .has-text-right-tablet-only {
    text-align: right !important;
  }
}

@media screen and (max-width: 1023px) {
  .has-text-right-touch {
    text-align: right !important;
  }
}

@media screen and (min-width: 1024px) {
  .has-text-right-desktop {
    text-align: right !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .has-text-right-desktop-only {
    text-align: right !important;
  }
}

@media screen and (min-width: 1216px) {
  .has-text-right-widescreen {
    text-align: right !important;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .has-text-right-widescreen-only {
    text-align: right !important;
  }
}

@media screen and (min-width: 1408px) {
  .has-text-right-fullhd {
    text-align: right !important;
  }
}

.is-capitalized {
  text-transform: capitalize !important;
}

.is-lowercase {
  text-transform: lowercase !important;
}

.is-uppercase {
  text-transform: uppercase !important;
}

.is-italic {
  font-style: italic !important;
}

.is-underlined {
  text-decoration: underline !important;
}

.has-text-weight-light {
  font-weight: 300 !important;
}

.has-text-weight-normal {
  font-weight: 400 !important;
}

.has-text-weight-medium {
  font-weight: 500 !important;
}

.has-text-weight-semibold {
  font-weight: 600 !important;
}

.has-text-weight-bold {
  font-weight: 700 !important;
}

.is-family-primary, .is-family-secondary, .is-family-sans-serif {
  font-family: Source Sans Pro, BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif !important;
}

.is-family-monospace, .is-family-code {
  font-family: courier, monospace !important;
}

.is-flex-direction-row {
  flex-direction: row !important;
}

.is-flex-direction-row-reverse {
  flex-direction: row-reverse !important;
}

.is-flex-direction-column {
  flex-direction: column !important;
}

.is-flex-direction-column-reverse {
  flex-direction: column-reverse !important;
}

.is-flex-wrap-nowrap {
  flex-wrap: nowrap !important;
}

.is-flex-wrap-wrap {
  flex-wrap: wrap !important;
}

.is-flex-wrap-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.is-justify-content-flex-start {
  justify-content: flex-start !important;
}

.is-justify-content-flex-end {
  justify-content: flex-end !important;
}

.is-justify-content-center {
  justify-content: center !important;
}

.is-justify-content-space-between {
  justify-content: space-between !important;
}

.is-justify-content-space-around {
  justify-content: space-around !important;
}

.is-justify-content-space-evenly {
  justify-content: space-evenly !important;
}

.is-justify-content-start {
  justify-content: start !important;
}

.is-justify-content-end {
  justify-content: end !important;
}

.is-justify-content-left {
  justify-content: left !important;
}

.is-justify-content-right {
  justify-content: right !important;
}

.is-align-content-flex-start {
  align-content: flex-start !important;
}

.is-align-content-flex-end {
  align-content: flex-end !important;
}

.is-align-content-center {
  align-content: center !important;
}

.is-align-content-space-between {
  align-content: space-between !important;
}

.is-align-content-space-around {
  align-content: space-around !important;
}

.is-align-content-space-evenly {
  align-content: space-evenly !important;
}

.is-align-content-stretch {
  align-content: stretch !important;
}

.is-align-content-start {
  align-content: start !important;
}

.is-align-content-end {
  align-content: end !important;
}

.is-align-content-baseline {
  align-content: baseline !important;
}

.is-align-items-stretch {
  align-items: stretch !important;
}

.is-align-items-flex-start {
  align-items: flex-start !important;
}

.is-align-items-flex-end {
  align-items: flex-end !important;
}

.is-align-items-center {
  align-items: center !important;
}

.is-align-items-baseline {
  align-items: baseline !important;
}

.is-align-items-start {
  align-items: start !important;
}

.is-align-items-end {
  align-items: end !important;
}

.is-align-items-self-start {
  align-items: self-start !important;
}

.is-align-items-self-end {
  align-items: self-end !important;
}

.is-align-self-auto {
  align-self: auto !important;
}

.is-align-self-flex-start {
  align-self: flex-start !important;
}

.is-align-self-flex-end {
  align-self: flex-end !important;
}

.is-align-self-center {
  align-self: center !important;
}

.is-align-self-baseline {
  align-self: baseline !important;
}

.is-align-self-stretch {
  align-self: stretch !important;
}

.is-flex-grow-0 {
  flex-grow: 0 !important;
}

.is-flex-grow-1 {
  flex-grow: 1 !important;
}

.is-flex-grow-2 {
  flex-grow: 2 !important;
}

.is-flex-grow-3 {
  flex-grow: 3 !important;
}

.is-flex-grow-4 {
  flex-grow: 4 !important;
}

.is-flex-grow-5 {
  flex-grow: 5 !important;
}

.is-flex-shrink-0 {
  flex-shrink: 0 !important;
}

.is-flex-shrink-1 {
  flex-shrink: 1 !important;
}

.is-flex-shrink-2 {
  flex-shrink: 2 !important;
}

.is-flex-shrink-3 {
  flex-shrink: 3 !important;
}

.is-flex-shrink-4 {
  flex-shrink: 4 !important;
}

.is-flex-shrink-5 {
  flex-shrink: 5 !important;
}

html, body, p, ol, ul, li, dl, dt, dd, blockquote, figure, fieldset, legend, textarea, pre, iframe, hr, h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: normal;
}

ul {
  list-style: none;
}

button, input, select, textarea {
  margin: 0;
}

html {
  box-sizing: border-box;
}

*, :before, :after {
  box-sizing: inherit;
}

img, video {
  max-width: 100%;
  height: auto;
}

iframe {
  border: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td, th {
  padding: 0;
}

td:not([align]), th:not([align]) {
  text-align: inherit;
}

html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  background-color: #f7f7f7;
  min-width: 300px;
  font-size: 16px;
  overflow-x: hidden;
  overflow-y: scroll;
}

article, aside, figure, footer, header, hgroup, section {
  display: block;
}

body, button, input, optgroup, select, textarea {
  font-family: Source Sans Pro, BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
}

code, pre {
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: auto;
  font-family: courier, monospace;
}

body {
  color: #545454;
  font-size: 1em;
  font-weight: 400;
  line-height: 1.5;
}

a {
  color: #485fc7;
  cursor: pointer;
  text-decoration: none;
}

a strong {
  color: currentColor;
}

a:hover {
  color: #363636;
}

code {
  color: #da1039;
  background-color: #f5f5f5;
  padding: .25em .5em;
  font-size: .875em;
  font-weight: normal;
}

hr {
  background-color: #f5f5f5;
  border: none;
  height: 2px;
  margin: 1.5rem 0;
  display: block;
}

img {
  max-width: 100%;
  height: auto;
}

input[type="checkbox"], input[type="radio"] {
  vertical-align: baseline;
}

small {
  font-size: .875em;
}

span {
  font-style: inherit;
  font-weight: inherit;
}

strong {
  color: #363636;
  font-weight: 700;
}

fieldset {
  border: none;
}

pre {
  -webkit-overflow-scrolling: touch;
  color: #545454;
  white-space: pre;
  word-wrap: normal;
  background-color: #f5f5f5;
  padding: 1.25rem 1.5rem;
  font-size: .875em;
  overflow-x: auto;
}

pre code {
  color: currentColor;
  background-color: #0000;
  padding: 0;
  font-size: 1em;
}

table td, table th {
  vertical-align: top;
}

table td:not([align]), table th:not([align]) {
  text-align: inherit;
}

table th {
  color: #363636;
}

@keyframes spinAround {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(359deg);
  }
}

.container {
  flex-grow: 1;
  width: auto;
  margin: 0 auto;
  position: relative;
}

.container.is-fluid {
  width: 100%;
  padding-left: 32px;
  padding-right: 32px;
  max-width: none !important;
}

@media screen and (min-width: 1024px) {
  .container {
    max-width: 960px;
  }
}

@media screen and (max-width: 1215px) {
  .container.is-widescreen:not(.is-max-desktop) {
    max-width: 1152px;
  }
}

@media screen and (max-width: 1407px) {
  .container.is-fullhd:not(.is-max-desktop):not(.is-max-widescreen) {
    max-width: 1344px;
  }
}

@media screen and (min-width: 1216px) {
  .container:not(.is-max-desktop) {
    max-width: 1152px;
  }
}

@media screen and (min-width: 1408px) {
  .container:not(.is-max-desktop):not(.is-max-widescreen) {
    max-width: 1344px;
  }
}

.column {
  flex: 1 1 0;
  padding: .75rem;
  display: block;
}

.columns.is-mobile > .column.is-narrow {
  width: unset;
  flex: none;
}

.columns.is-mobile > .column.is-full {
  flex: none;
  width: 100%;
}

.columns.is-mobile > .column.is-three-quarters {
  flex: none;
  width: 75%;
}

.columns.is-mobile > .column.is-two-thirds {
  flex: none;
  width: 66.6666%;
}

.columns.is-mobile > .column.is-half {
  flex: none;
  width: 50%;
}

.columns.is-mobile > .column.is-one-third {
  flex: none;
  width: 33.3333%;
}

.columns.is-mobile > .column.is-one-quarter {
  flex: none;
  width: 25%;
}

.columns.is-mobile > .column.is-one-fifth {
  flex: none;
  width: 20%;
}

.columns.is-mobile > .column.is-two-fifths {
  flex: none;
  width: 40%;
}

.columns.is-mobile > .column.is-three-fifths {
  flex: none;
  width: 60%;
}

.columns.is-mobile > .column.is-four-fifths {
  flex: none;
  width: 80%;
}

.columns.is-mobile > .column.is-offset-three-quarters {
  margin-left: 75%;
}

.columns.is-mobile > .column.is-offset-two-thirds {
  margin-left: 66.6666%;
}

.columns.is-mobile > .column.is-offset-half {
  margin-left: 50%;
}

.columns.is-mobile > .column.is-offset-one-third {
  margin-left: 33.3333%;
}

.columns.is-mobile > .column.is-offset-one-quarter {
  margin-left: 25%;
}

.columns.is-mobile > .column.is-offset-one-fifth {
  margin-left: 20%;
}

.columns.is-mobile > .column.is-offset-two-fifths {
  margin-left: 40%;
}

.columns.is-mobile > .column.is-offset-three-fifths {
  margin-left: 60%;
}

.columns.is-mobile > .column.is-offset-four-fifths {
  margin-left: 80%;
}

.columns.is-mobile > .column.is-0 {
  flex: none;
  width: 0%;
}

.columns.is-mobile > .column.is-offset-0 {
  margin-left: 0%;
}

.columns.is-mobile > .column.is-1 {
  flex: none;
  width: 8.33333%;
}

.columns.is-mobile > .column.is-offset-1 {
  margin-left: 8.33333%;
}

.columns.is-mobile > .column.is-2 {
  flex: none;
  width: 16.6667%;
}

.columns.is-mobile > .column.is-offset-2 {
  margin-left: 16.6667%;
}

.columns.is-mobile > .column.is-3 {
  flex: none;
  width: 25%;
}

.columns.is-mobile > .column.is-offset-3 {
  margin-left: 25%;
}

.columns.is-mobile > .column.is-4 {
  flex: none;
  width: 33.3333%;
}

.columns.is-mobile > .column.is-offset-4 {
  margin-left: 33.3333%;
}

.columns.is-mobile > .column.is-5 {
  flex: none;
  width: 41.6667%;
}

.columns.is-mobile > .column.is-offset-5 {
  margin-left: 41.6667%;
}

.columns.is-mobile > .column.is-6 {
  flex: none;
  width: 50%;
}

.columns.is-mobile > .column.is-offset-6 {
  margin-left: 50%;
}

.columns.is-mobile > .column.is-7 {
  flex: none;
  width: 58.3333%;
}

.columns.is-mobile > .column.is-offset-7 {
  margin-left: 58.3333%;
}

.columns.is-mobile > .column.is-8 {
  flex: none;
  width: 66.6667%;
}

.columns.is-mobile > .column.is-offset-8 {
  margin-left: 66.6667%;
}

.columns.is-mobile > .column.is-9 {
  flex: none;
  width: 75%;
}

.columns.is-mobile > .column.is-offset-9 {
  margin-left: 75%;
}

.columns.is-mobile > .column.is-10 {
  flex: none;
  width: 83.3333%;
}

.columns.is-mobile > .column.is-offset-10 {
  margin-left: 83.3333%;
}

.columns.is-mobile > .column.is-11 {
  flex: none;
  width: 91.6667%;
}

.columns.is-mobile > .column.is-offset-11 {
  margin-left: 91.6667%;
}

.columns.is-mobile > .column.is-12 {
  flex: none;
  width: 100%;
}

.columns.is-mobile > .column.is-offset-12 {
  margin-left: 100%;
}

@media screen and (max-width: 768px) {
  .column.is-narrow-mobile {
    width: unset;
    flex: none;
  }

  .column.is-full-mobile {
    flex: none;
    width: 100%;
  }

  .column.is-three-quarters-mobile {
    flex: none;
    width: 75%;
  }

  .column.is-two-thirds-mobile {
    flex: none;
    width: 66.6666%;
  }

  .column.is-half-mobile {
    flex: none;
    width: 50%;
  }

  .column.is-one-third-mobile {
    flex: none;
    width: 33.3333%;
  }

  .column.is-one-quarter-mobile {
    flex: none;
    width: 25%;
  }

  .column.is-one-fifth-mobile {
    flex: none;
    width: 20%;
  }

  .column.is-two-fifths-mobile {
    flex: none;
    width: 40%;
  }

  .column.is-three-fifths-mobile {
    flex: none;
    width: 60%;
  }

  .column.is-four-fifths-mobile {
    flex: none;
    width: 80%;
  }

  .column.is-offset-three-quarters-mobile {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds-mobile {
    margin-left: 66.6666%;
  }

  .column.is-offset-half-mobile {
    margin-left: 50%;
  }

  .column.is-offset-one-third-mobile {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter-mobile {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth-mobile {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths-mobile {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths-mobile {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths-mobile {
    margin-left: 80%;
  }

  .column.is-0-mobile {
    flex: none;
    width: 0%;
  }

  .column.is-offset-0-mobile {
    margin-left: 0%;
  }

  .column.is-1-mobile {
    flex: none;
    width: 8.33333%;
  }

  .column.is-offset-1-mobile {
    margin-left: 8.33333%;
  }

  .column.is-2-mobile {
    flex: none;
    width: 16.6667%;
  }

  .column.is-offset-2-mobile {
    margin-left: 16.6667%;
  }

  .column.is-3-mobile {
    flex: none;
    width: 25%;
  }

  .column.is-offset-3-mobile {
    margin-left: 25%;
  }

  .column.is-4-mobile {
    flex: none;
    width: 33.3333%;
  }

  .column.is-offset-4-mobile {
    margin-left: 33.3333%;
  }

  .column.is-5-mobile {
    flex: none;
    width: 41.6667%;
  }

  .column.is-offset-5-mobile {
    margin-left: 41.6667%;
  }

  .column.is-6-mobile {
    flex: none;
    width: 50%;
  }

  .column.is-offset-6-mobile {
    margin-left: 50%;
  }

  .column.is-7-mobile {
    flex: none;
    width: 58.3333%;
  }

  .column.is-offset-7-mobile {
    margin-left: 58.3333%;
  }

  .column.is-8-mobile {
    flex: none;
    width: 66.6667%;
  }

  .column.is-offset-8-mobile {
    margin-left: 66.6667%;
  }

  .column.is-9-mobile {
    flex: none;
    width: 75%;
  }

  .column.is-offset-9-mobile {
    margin-left: 75%;
  }

  .column.is-10-mobile {
    flex: none;
    width: 83.3333%;
  }

  .column.is-offset-10-mobile {
    margin-left: 83.3333%;
  }

  .column.is-11-mobile {
    flex: none;
    width: 91.6667%;
  }

  .column.is-offset-11-mobile {
    margin-left: 91.6667%;
  }

  .column.is-12-mobile {
    flex: none;
    width: 100%;
  }

  .column.is-offset-12-mobile {
    margin-left: 100%;
  }
}

@media screen and (min-width: 769px), print {
  .column.is-narrow, .column.is-narrow-tablet {
    width: unset;
    flex: none;
  }

  .column.is-full, .column.is-full-tablet {
    flex: none;
    width: 100%;
  }

  .column.is-three-quarters, .column.is-three-quarters-tablet {
    flex: none;
    width: 75%;
  }

  .column.is-two-thirds, .column.is-two-thirds-tablet {
    flex: none;
    width: 66.6666%;
  }

  .column.is-half, .column.is-half-tablet {
    flex: none;
    width: 50%;
  }

  .column.is-one-third, .column.is-one-third-tablet {
    flex: none;
    width: 33.3333%;
  }

  .column.is-one-quarter, .column.is-one-quarter-tablet {
    flex: none;
    width: 25%;
  }

  .column.is-one-fifth, .column.is-one-fifth-tablet {
    flex: none;
    width: 20%;
  }

  .column.is-two-fifths, .column.is-two-fifths-tablet {
    flex: none;
    width: 40%;
  }

  .column.is-three-fifths, .column.is-three-fifths-tablet {
    flex: none;
    width: 60%;
  }

  .column.is-four-fifths, .column.is-four-fifths-tablet {
    flex: none;
    width: 80%;
  }

  .column.is-offset-three-quarters, .column.is-offset-three-quarters-tablet {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds, .column.is-offset-two-thirds-tablet {
    margin-left: 66.6666%;
  }

  .column.is-offset-half, .column.is-offset-half-tablet {
    margin-left: 50%;
  }

  .column.is-offset-one-third, .column.is-offset-one-third-tablet {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter, .column.is-offset-one-quarter-tablet {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth, .column.is-offset-one-fifth-tablet {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths, .column.is-offset-two-fifths-tablet {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths, .column.is-offset-three-fifths-tablet {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths, .column.is-offset-four-fifths-tablet {
    margin-left: 80%;
  }

  .column.is-0, .column.is-0-tablet {
    flex: none;
    width: 0%;
  }

  .column.is-offset-0, .column.is-offset-0-tablet {
    margin-left: 0%;
  }

  .column.is-1, .column.is-1-tablet {
    flex: none;
    width: 8.33333%;
  }

  .column.is-offset-1, .column.is-offset-1-tablet {
    margin-left: 8.33333%;
  }

  .column.is-2, .column.is-2-tablet {
    flex: none;
    width: 16.6667%;
  }

  .column.is-offset-2, .column.is-offset-2-tablet {
    margin-left: 16.6667%;
  }

  .column.is-3, .column.is-3-tablet {
    flex: none;
    width: 25%;
  }

  .column.is-offset-3, .column.is-offset-3-tablet {
    margin-left: 25%;
  }

  .column.is-4, .column.is-4-tablet {
    flex: none;
    width: 33.3333%;
  }

  .column.is-offset-4, .column.is-offset-4-tablet {
    margin-left: 33.3333%;
  }

  .column.is-5, .column.is-5-tablet {
    flex: none;
    width: 41.6667%;
  }

  .column.is-offset-5, .column.is-offset-5-tablet {
    margin-left: 41.6667%;
  }

  .column.is-6, .column.is-6-tablet {
    flex: none;
    width: 50%;
  }

  .column.is-offset-6, .column.is-offset-6-tablet {
    margin-left: 50%;
  }

  .column.is-7, .column.is-7-tablet {
    flex: none;
    width: 58.3333%;
  }

  .column.is-offset-7, .column.is-offset-7-tablet {
    margin-left: 58.3333%;
  }

  .column.is-8, .column.is-8-tablet {
    flex: none;
    width: 66.6667%;
  }

  .column.is-offset-8, .column.is-offset-8-tablet {
    margin-left: 66.6667%;
  }

  .column.is-9, .column.is-9-tablet {
    flex: none;
    width: 75%;
  }

  .column.is-offset-9, .column.is-offset-9-tablet {
    margin-left: 75%;
  }

  .column.is-10, .column.is-10-tablet {
    flex: none;
    width: 83.3333%;
  }

  .column.is-offset-10, .column.is-offset-10-tablet {
    margin-left: 83.3333%;
  }

  .column.is-11, .column.is-11-tablet {
    flex: none;
    width: 91.6667%;
  }

  .column.is-offset-11, .column.is-offset-11-tablet {
    margin-left: 91.6667%;
  }

  .column.is-12, .column.is-12-tablet {
    flex: none;
    width: 100%;
  }

  .column.is-offset-12, .column.is-offset-12-tablet {
    margin-left: 100%;
  }
}

@media screen and (max-width: 1023px) {
  .column.is-narrow-touch {
    width: unset;
    flex: none;
  }

  .column.is-full-touch {
    flex: none;
    width: 100%;
  }

  .column.is-three-quarters-touch {
    flex: none;
    width: 75%;
  }

  .column.is-two-thirds-touch {
    flex: none;
    width: 66.6666%;
  }

  .column.is-half-touch {
    flex: none;
    width: 50%;
  }

  .column.is-one-third-touch {
    flex: none;
    width: 33.3333%;
  }

  .column.is-one-quarter-touch {
    flex: none;
    width: 25%;
  }

  .column.is-one-fifth-touch {
    flex: none;
    width: 20%;
  }

  .column.is-two-fifths-touch {
    flex: none;
    width: 40%;
  }

  .column.is-three-fifths-touch {
    flex: none;
    width: 60%;
  }

  .column.is-four-fifths-touch {
    flex: none;
    width: 80%;
  }

  .column.is-offset-three-quarters-touch {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds-touch {
    margin-left: 66.6666%;
  }

  .column.is-offset-half-touch {
    margin-left: 50%;
  }

  .column.is-offset-one-third-touch {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter-touch {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth-touch {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths-touch {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths-touch {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths-touch {
    margin-left: 80%;
  }

  .column.is-0-touch {
    flex: none;
    width: 0%;
  }

  .column.is-offset-0-touch {
    margin-left: 0%;
  }

  .column.is-1-touch {
    flex: none;
    width: 8.33333%;
  }

  .column.is-offset-1-touch {
    margin-left: 8.33333%;
  }

  .column.is-2-touch {
    flex: none;
    width: 16.6667%;
  }

  .column.is-offset-2-touch {
    margin-left: 16.6667%;
  }

  .column.is-3-touch {
    flex: none;
    width: 25%;
  }

  .column.is-offset-3-touch {
    margin-left: 25%;
  }

  .column.is-4-touch {
    flex: none;
    width: 33.3333%;
  }

  .column.is-offset-4-touch {
    margin-left: 33.3333%;
  }

  .column.is-5-touch {
    flex: none;
    width: 41.6667%;
  }

  .column.is-offset-5-touch {
    margin-left: 41.6667%;
  }

  .column.is-6-touch {
    flex: none;
    width: 50%;
  }

  .column.is-offset-6-touch {
    margin-left: 50%;
  }

  .column.is-7-touch {
    flex: none;
    width: 58.3333%;
  }

  .column.is-offset-7-touch {
    margin-left: 58.3333%;
  }

  .column.is-8-touch {
    flex: none;
    width: 66.6667%;
  }

  .column.is-offset-8-touch {
    margin-left: 66.6667%;
  }

  .column.is-9-touch {
    flex: none;
    width: 75%;
  }

  .column.is-offset-9-touch {
    margin-left: 75%;
  }

  .column.is-10-touch {
    flex: none;
    width: 83.3333%;
  }

  .column.is-offset-10-touch {
    margin-left: 83.3333%;
  }

  .column.is-11-touch {
    flex: none;
    width: 91.6667%;
  }

  .column.is-offset-11-touch {
    margin-left: 91.6667%;
  }

  .column.is-12-touch {
    flex: none;
    width: 100%;
  }

  .column.is-offset-12-touch {
    margin-left: 100%;
  }
}

@media screen and (min-width: 1024px) {
  .column.is-narrow-desktop {
    width: unset;
    flex: none;
  }

  .column.is-full-desktop {
    flex: none;
    width: 100%;
  }

  .column.is-three-quarters-desktop {
    flex: none;
    width: 75%;
  }

  .column.is-two-thirds-desktop {
    flex: none;
    width: 66.6666%;
  }

  .column.is-half-desktop {
    flex: none;
    width: 50%;
  }

  .column.is-one-third-desktop {
    flex: none;
    width: 33.3333%;
  }

  .column.is-one-quarter-desktop {
    flex: none;
    width: 25%;
  }

  .column.is-one-fifth-desktop {
    flex: none;
    width: 20%;
  }

  .column.is-two-fifths-desktop {
    flex: none;
    width: 40%;
  }

  .column.is-three-fifths-desktop {
    flex: none;
    width: 60%;
  }

  .column.is-four-fifths-desktop {
    flex: none;
    width: 80%;
  }

  .column.is-offset-three-quarters-desktop {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds-desktop {
    margin-left: 66.6666%;
  }

  .column.is-offset-half-desktop {
    margin-left: 50%;
  }

  .column.is-offset-one-third-desktop {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter-desktop {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth-desktop {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths-desktop {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths-desktop {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths-desktop {
    margin-left: 80%;
  }

  .column.is-0-desktop {
    flex: none;
    width: 0%;
  }

  .column.is-offset-0-desktop {
    margin-left: 0%;
  }

  .column.is-1-desktop {
    flex: none;
    width: 8.33333%;
  }

  .column.is-offset-1-desktop {
    margin-left: 8.33333%;
  }

  .column.is-2-desktop {
    flex: none;
    width: 16.6667%;
  }

  .column.is-offset-2-desktop {
    margin-left: 16.6667%;
  }

  .column.is-3-desktop {
    flex: none;
    width: 25%;
  }

  .column.is-offset-3-desktop {
    margin-left: 25%;
  }

  .column.is-4-desktop {
    flex: none;
    width: 33.3333%;
  }

  .column.is-offset-4-desktop {
    margin-left: 33.3333%;
  }

  .column.is-5-desktop {
    flex: none;
    width: 41.6667%;
  }

  .column.is-offset-5-desktop {
    margin-left: 41.6667%;
  }

  .column.is-6-desktop {
    flex: none;
    width: 50%;
  }

  .column.is-offset-6-desktop {
    margin-left: 50%;
  }

  .column.is-7-desktop {
    flex: none;
    width: 58.3333%;
  }

  .column.is-offset-7-desktop {
    margin-left: 58.3333%;
  }

  .column.is-8-desktop {
    flex: none;
    width: 66.6667%;
  }

  .column.is-offset-8-desktop {
    margin-left: 66.6667%;
  }

  .column.is-9-desktop {
    flex: none;
    width: 75%;
  }

  .column.is-offset-9-desktop {
    margin-left: 75%;
  }

  .column.is-10-desktop {
    flex: none;
    width: 83.3333%;
  }

  .column.is-offset-10-desktop {
    margin-left: 83.3333%;
  }

  .column.is-11-desktop {
    flex: none;
    width: 91.6667%;
  }

  .column.is-offset-11-desktop {
    margin-left: 91.6667%;
  }

  .column.is-12-desktop {
    flex: none;
    width: 100%;
  }

  .column.is-offset-12-desktop {
    margin-left: 100%;
  }
}

@media screen and (min-width: 1216px) {
  .column.is-narrow-widescreen {
    width: unset;
    flex: none;
  }

  .column.is-full-widescreen {
    flex: none;
    width: 100%;
  }

  .column.is-three-quarters-widescreen {
    flex: none;
    width: 75%;
  }

  .column.is-two-thirds-widescreen {
    flex: none;
    width: 66.6666%;
  }

  .column.is-half-widescreen {
    flex: none;
    width: 50%;
  }

  .column.is-one-third-widescreen {
    flex: none;
    width: 33.3333%;
  }

  .column.is-one-quarter-widescreen {
    flex: none;
    width: 25%;
  }

  .column.is-one-fifth-widescreen {
    flex: none;
    width: 20%;
  }

  .column.is-two-fifths-widescreen {
    flex: none;
    width: 40%;
  }

  .column.is-three-fifths-widescreen {
    flex: none;
    width: 60%;
  }

  .column.is-four-fifths-widescreen {
    flex: none;
    width: 80%;
  }

  .column.is-offset-three-quarters-widescreen {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds-widescreen {
    margin-left: 66.6666%;
  }

  .column.is-offset-half-widescreen {
    margin-left: 50%;
  }

  .column.is-offset-one-third-widescreen {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter-widescreen {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth-widescreen {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths-widescreen {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths-widescreen {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths-widescreen {
    margin-left: 80%;
  }

  .column.is-0-widescreen {
    flex: none;
    width: 0%;
  }

  .column.is-offset-0-widescreen {
    margin-left: 0%;
  }

  .column.is-1-widescreen {
    flex: none;
    width: 8.33333%;
  }

  .column.is-offset-1-widescreen {
    margin-left: 8.33333%;
  }

  .column.is-2-widescreen {
    flex: none;
    width: 16.6667%;
  }

  .column.is-offset-2-widescreen {
    margin-left: 16.6667%;
  }

  .column.is-3-widescreen {
    flex: none;
    width: 25%;
  }

  .column.is-offset-3-widescreen {
    margin-left: 25%;
  }

  .column.is-4-widescreen {
    flex: none;
    width: 33.3333%;
  }

  .column.is-offset-4-widescreen {
    margin-left: 33.3333%;
  }

  .column.is-5-widescreen {
    flex: none;
    width: 41.6667%;
  }

  .column.is-offset-5-widescreen {
    margin-left: 41.6667%;
  }

  .column.is-6-widescreen {
    flex: none;
    width: 50%;
  }

  .column.is-offset-6-widescreen {
    margin-left: 50%;
  }

  .column.is-7-widescreen {
    flex: none;
    width: 58.3333%;
  }

  .column.is-offset-7-widescreen {
    margin-left: 58.3333%;
  }

  .column.is-8-widescreen {
    flex: none;
    width: 66.6667%;
  }

  .column.is-offset-8-widescreen {
    margin-left: 66.6667%;
  }

  .column.is-9-widescreen {
    flex: none;
    width: 75%;
  }

  .column.is-offset-9-widescreen {
    margin-left: 75%;
  }

  .column.is-10-widescreen {
    flex: none;
    width: 83.3333%;
  }

  .column.is-offset-10-widescreen {
    margin-left: 83.3333%;
  }

  .column.is-11-widescreen {
    flex: none;
    width: 91.6667%;
  }

  .column.is-offset-11-widescreen {
    margin-left: 91.6667%;
  }

  .column.is-12-widescreen {
    flex: none;
    width: 100%;
  }

  .column.is-offset-12-widescreen {
    margin-left: 100%;
  }
}

@media screen and (min-width: 1408px) {
  .column.is-narrow-fullhd {
    width: unset;
    flex: none;
  }

  .column.is-full-fullhd {
    flex: none;
    width: 100%;
  }

  .column.is-three-quarters-fullhd {
    flex: none;
    width: 75%;
  }

  .column.is-two-thirds-fullhd {
    flex: none;
    width: 66.6666%;
  }

  .column.is-half-fullhd {
    flex: none;
    width: 50%;
  }

  .column.is-one-third-fullhd {
    flex: none;
    width: 33.3333%;
  }

  .column.is-one-quarter-fullhd {
    flex: none;
    width: 25%;
  }

  .column.is-one-fifth-fullhd {
    flex: none;
    width: 20%;
  }

  .column.is-two-fifths-fullhd {
    flex: none;
    width: 40%;
  }

  .column.is-three-fifths-fullhd {
    flex: none;
    width: 60%;
  }

  .column.is-four-fifths-fullhd {
    flex: none;
    width: 80%;
  }

  .column.is-offset-three-quarters-fullhd {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds-fullhd {
    margin-left: 66.6666%;
  }

  .column.is-offset-half-fullhd {
    margin-left: 50%;
  }

  .column.is-offset-one-third-fullhd {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter-fullhd {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth-fullhd {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths-fullhd {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths-fullhd {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths-fullhd {
    margin-left: 80%;
  }

  .column.is-0-fullhd {
    flex: none;
    width: 0%;
  }

  .column.is-offset-0-fullhd {
    margin-left: 0%;
  }

  .column.is-1-fullhd {
    flex: none;
    width: 8.33333%;
  }

  .column.is-offset-1-fullhd {
    margin-left: 8.33333%;
  }

  .column.is-2-fullhd {
    flex: none;
    width: 16.6667%;
  }

  .column.is-offset-2-fullhd {
    margin-left: 16.6667%;
  }

  .column.is-3-fullhd {
    flex: none;
    width: 25%;
  }

  .column.is-offset-3-fullhd {
    margin-left: 25%;
  }

  .column.is-4-fullhd {
    flex: none;
    width: 33.3333%;
  }

  .column.is-offset-4-fullhd {
    margin-left: 33.3333%;
  }

  .column.is-5-fullhd {
    flex: none;
    width: 41.6667%;
  }

  .column.is-offset-5-fullhd {
    margin-left: 41.6667%;
  }

  .column.is-6-fullhd {
    flex: none;
    width: 50%;
  }

  .column.is-offset-6-fullhd {
    margin-left: 50%;
  }

  .column.is-7-fullhd {
    flex: none;
    width: 58.3333%;
  }

  .column.is-offset-7-fullhd {
    margin-left: 58.3333%;
  }

  .column.is-8-fullhd {
    flex: none;
    width: 66.6667%;
  }

  .column.is-offset-8-fullhd {
    margin-left: 66.6667%;
  }

  .column.is-9-fullhd {
    flex: none;
    width: 75%;
  }

  .column.is-offset-9-fullhd {
    margin-left: 75%;
  }

  .column.is-10-fullhd {
    flex: none;
    width: 83.3333%;
  }

  .column.is-offset-10-fullhd {
    margin-left: 83.3333%;
  }

  .column.is-11-fullhd {
    flex: none;
    width: 91.6667%;
  }

  .column.is-offset-11-fullhd {
    margin-left: 91.6667%;
  }

  .column.is-12-fullhd {
    flex: none;
    width: 100%;
  }

  .column.is-offset-12-fullhd {
    margin-left: 100%;
  }
}

.columns {
  margin-top: -.75rem;
  margin-left: -.75rem;
  margin-right: -.75rem;
}

.columns:last-child {
  margin-bottom: -.75rem;
}

.columns:not(:last-child) {
  margin-bottom: .75rem;
}

.columns.is-centered {
  justify-content: center;
}

.columns.is-gapless {
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
}

.columns.is-gapless > .column {
  margin: 0;
  padding: 0 !important;
}

.columns.is-gapless:not(:last-child) {
  margin-bottom: 1.5rem;
}

.columns.is-gapless:last-child {
  margin-bottom: 0;
}

.columns.is-mobile {
  display: flex;
}

.columns.is-multiline {
  flex-wrap: wrap;
}

.columns.is-vcentered {
  align-items: center;
}

@media screen and (min-width: 769px), print {
  .columns:not(.is-desktop) {
    display: flex;
  }
}

@media screen and (min-width: 1024px) {
  .columns.is-desktop {
    display: flex;
  }
}

.columns.is-variable {
  --columnGap: .75rem;
  margin-left: calc(-1 * var(--columnGap));
  margin-right: calc(-1 * var(--columnGap));
}

.columns.is-variable > .column {
  padding-left: var(--columnGap);
  padding-right: var(--columnGap);
}

.columns.is-variable.is-0 {
  --columnGap: 0rem;
}

@media screen and (max-width: 768px) {
  .columns.is-variable.is-0-mobile {
    --columnGap: 0rem;
  }
}

@media screen and (min-width: 769px), print {
  .columns.is-variable.is-0-tablet {
    --columnGap: 0rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .columns.is-variable.is-0-tablet-only {
    --columnGap: 0rem;
  }
}

@media screen and (max-width: 1023px) {
  .columns.is-variable.is-0-touch {
    --columnGap: 0rem;
  }
}

@media screen and (min-width: 1024px) {
  .columns.is-variable.is-0-desktop {
    --columnGap: 0rem;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .columns.is-variable.is-0-desktop-only {
    --columnGap: 0rem;
  }
}

@media screen and (min-width: 1216px) {
  .columns.is-variable.is-0-widescreen {
    --columnGap: 0rem;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .columns.is-variable.is-0-widescreen-only {
    --columnGap: 0rem;
  }
}

@media screen and (min-width: 1408px) {
  .columns.is-variable.is-0-fullhd {
    --columnGap: 0rem;
  }
}

.columns.is-variable.is-1 {
  --columnGap: .25rem;
}

@media screen and (max-width: 768px) {
  .columns.is-variable.is-1-mobile {
    --columnGap: .25rem;
  }
}

@media screen and (min-width: 769px), print {
  .columns.is-variable.is-1-tablet {
    --columnGap: .25rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .columns.is-variable.is-1-tablet-only {
    --columnGap: .25rem;
  }
}

@media screen and (max-width: 1023px) {
  .columns.is-variable.is-1-touch {
    --columnGap: .25rem;
  }
}

@media screen and (min-width: 1024px) {
  .columns.is-variable.is-1-desktop {
    --columnGap: .25rem;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .columns.is-variable.is-1-desktop-only {
    --columnGap: .25rem;
  }
}

@media screen and (min-width: 1216px) {
  .columns.is-variable.is-1-widescreen {
    --columnGap: .25rem;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .columns.is-variable.is-1-widescreen-only {
    --columnGap: .25rem;
  }
}

@media screen and (min-width: 1408px) {
  .columns.is-variable.is-1-fullhd {
    --columnGap: .25rem;
  }
}

.columns.is-variable.is-2 {
  --columnGap: .5rem;
}

@media screen and (max-width: 768px) {
  .columns.is-variable.is-2-mobile {
    --columnGap: .5rem;
  }
}

@media screen and (min-width: 769px), print {
  .columns.is-variable.is-2-tablet {
    --columnGap: .5rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .columns.is-variable.is-2-tablet-only {
    --columnGap: .5rem;
  }
}

@media screen and (max-width: 1023px) {
  .columns.is-variable.is-2-touch {
    --columnGap: .5rem;
  }
}

@media screen and (min-width: 1024px) {
  .columns.is-variable.is-2-desktop {
    --columnGap: .5rem;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .columns.is-variable.is-2-desktop-only {
    --columnGap: .5rem;
  }
}

@media screen and (min-width: 1216px) {
  .columns.is-variable.is-2-widescreen {
    --columnGap: .5rem;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .columns.is-variable.is-2-widescreen-only {
    --columnGap: .5rem;
  }
}

@media screen and (min-width: 1408px) {
  .columns.is-variable.is-2-fullhd {
    --columnGap: .5rem;
  }
}

.columns.is-variable.is-3 {
  --columnGap: .75rem;
}

@media screen and (max-width: 768px) {
  .columns.is-variable.is-3-mobile {
    --columnGap: .75rem;
  }
}

@media screen and (min-width: 769px), print {
  .columns.is-variable.is-3-tablet {
    --columnGap: .75rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .columns.is-variable.is-3-tablet-only {
    --columnGap: .75rem;
  }
}

@media screen and (max-width: 1023px) {
  .columns.is-variable.is-3-touch {
    --columnGap: .75rem;
  }
}

@media screen and (min-width: 1024px) {
  .columns.is-variable.is-3-desktop {
    --columnGap: .75rem;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .columns.is-variable.is-3-desktop-only {
    --columnGap: .75rem;
  }
}

@media screen and (min-width: 1216px) {
  .columns.is-variable.is-3-widescreen {
    --columnGap: .75rem;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .columns.is-variable.is-3-widescreen-only {
    --columnGap: .75rem;
  }
}

@media screen and (min-width: 1408px) {
  .columns.is-variable.is-3-fullhd {
    --columnGap: .75rem;
  }
}

.columns.is-variable.is-4 {
  --columnGap: 1rem;
}

@media screen and (max-width: 768px) {
  .columns.is-variable.is-4-mobile {
    --columnGap: 1rem;
  }
}

@media screen and (min-width: 769px), print {
  .columns.is-variable.is-4-tablet {
    --columnGap: 1rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .columns.is-variable.is-4-tablet-only {
    --columnGap: 1rem;
  }
}

@media screen and (max-width: 1023px) {
  .columns.is-variable.is-4-touch {
    --columnGap: 1rem;
  }
}

@media screen and (min-width: 1024px) {
  .columns.is-variable.is-4-desktop {
    --columnGap: 1rem;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .columns.is-variable.is-4-desktop-only {
    --columnGap: 1rem;
  }
}

@media screen and (min-width: 1216px) {
  .columns.is-variable.is-4-widescreen {
    --columnGap: 1rem;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .columns.is-variable.is-4-widescreen-only {
    --columnGap: 1rem;
  }
}

@media screen and (min-width: 1408px) {
  .columns.is-variable.is-4-fullhd {
    --columnGap: 1rem;
  }
}

.columns.is-variable.is-5 {
  --columnGap: 1.25rem;
}

@media screen and (max-width: 768px) {
  .columns.is-variable.is-5-mobile {
    --columnGap: 1.25rem;
  }
}

@media screen and (min-width: 769px), print {
  .columns.is-variable.is-5-tablet {
    --columnGap: 1.25rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .columns.is-variable.is-5-tablet-only {
    --columnGap: 1.25rem;
  }
}

@media screen and (max-width: 1023px) {
  .columns.is-variable.is-5-touch {
    --columnGap: 1.25rem;
  }
}

@media screen and (min-width: 1024px) {
  .columns.is-variable.is-5-desktop {
    --columnGap: 1.25rem;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .columns.is-variable.is-5-desktop-only {
    --columnGap: 1.25rem;
  }
}

@media screen and (min-width: 1216px) {
  .columns.is-variable.is-5-widescreen {
    --columnGap: 1.25rem;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .columns.is-variable.is-5-widescreen-only {
    --columnGap: 1.25rem;
  }
}

@media screen and (min-width: 1408px) {
  .columns.is-variable.is-5-fullhd {
    --columnGap: 1.25rem;
  }
}

.columns.is-variable.is-6 {
  --columnGap: 1.5rem;
}

@media screen and (max-width: 768px) {
  .columns.is-variable.is-6-mobile {
    --columnGap: 1.5rem;
  }
}

@media screen and (min-width: 769px), print {
  .columns.is-variable.is-6-tablet {
    --columnGap: 1.5rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .columns.is-variable.is-6-tablet-only {
    --columnGap: 1.5rem;
  }
}

@media screen and (max-width: 1023px) {
  .columns.is-variable.is-6-touch {
    --columnGap: 1.5rem;
  }
}

@media screen and (min-width: 1024px) {
  .columns.is-variable.is-6-desktop {
    --columnGap: 1.5rem;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .columns.is-variable.is-6-desktop-only {
    --columnGap: 1.5rem;
  }
}

@media screen and (min-width: 1216px) {
  .columns.is-variable.is-6-widescreen {
    --columnGap: 1.5rem;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .columns.is-variable.is-6-widescreen-only {
    --columnGap: 1.5rem;
  }
}

@media screen and (min-width: 1408px) {
  .columns.is-variable.is-6-fullhd {
    --columnGap: 1.5rem;
  }
}

.columns.is-variable.is-7 {
  --columnGap: 1.75rem;
}

@media screen and (max-width: 768px) {
  .columns.is-variable.is-7-mobile {
    --columnGap: 1.75rem;
  }
}

@media screen and (min-width: 769px), print {
  .columns.is-variable.is-7-tablet {
    --columnGap: 1.75rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .columns.is-variable.is-7-tablet-only {
    --columnGap: 1.75rem;
  }
}

@media screen and (max-width: 1023px) {
  .columns.is-variable.is-7-touch {
    --columnGap: 1.75rem;
  }
}

@media screen and (min-width: 1024px) {
  .columns.is-variable.is-7-desktop {
    --columnGap: 1.75rem;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .columns.is-variable.is-7-desktop-only {
    --columnGap: 1.75rem;
  }
}

@media screen and (min-width: 1216px) {
  .columns.is-variable.is-7-widescreen {
    --columnGap: 1.75rem;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .columns.is-variable.is-7-widescreen-only {
    --columnGap: 1.75rem;
  }
}

@media screen and (min-width: 1408px) {
  .columns.is-variable.is-7-fullhd {
    --columnGap: 1.75rem;
  }
}

.columns.is-variable.is-8 {
  --columnGap: 2rem;
}

@media screen and (max-width: 768px) {
  .columns.is-variable.is-8-mobile {
    --columnGap: 2rem;
  }
}

@media screen and (min-width: 769px), print {
  .columns.is-variable.is-8-tablet {
    --columnGap: 2rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .columns.is-variable.is-8-tablet-only {
    --columnGap: 2rem;
  }
}

@media screen and (max-width: 1023px) {
  .columns.is-variable.is-8-touch {
    --columnGap: 2rem;
  }
}

@media screen and (min-width: 1024px) {
  .columns.is-variable.is-8-desktop {
    --columnGap: 2rem;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .columns.is-variable.is-8-desktop-only {
    --columnGap: 2rem;
  }
}

@media screen and (min-width: 1216px) {
  .columns.is-variable.is-8-widescreen {
    --columnGap: 2rem;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .columns.is-variable.is-8-widescreen-only {
    --columnGap: 2rem;
  }
}

@media screen and (min-width: 1408px) {
  .columns.is-variable.is-8-fullhd {
    --columnGap: 2rem;
  }
}

.section {
  padding: 3rem 1.5rem;
}

@media screen and (min-width: 1024px) {
  .section {
    padding: 3rem;
  }

  .section.is-medium {
    padding: 9rem 4.5rem;
  }

  .section.is-large {
    padding: 18rem 6rem;
  }
}

.footer {
  background-image: url("galaxy-background.d7dfcd02.jpg");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}

@media screen and (max-width: 1599px) {
  .footer {
    background-image: url("galaxy-background--1600.88147e0d.jpg");
  }
}

@media screen and (max-width: 1365px) {
  .footer {
    background-image: url("galaxy-background--1366.8c83cf76.jpg");
  }
}

@media screen and (max-width: 1023px) {
  .footer {
    background-image: url("galaxy-background--1024.08196622.jpg");
  }
}

@media screen and (max-width: 767px) {
  .footer {
    background-image: url("galaxy-background--768.df5c4138.jpg");
  }
}

@media screen and (max-width: 639px) {
  .footer {
    background-image: url("galaxy-background--640.755b52fd.jpg");
  }
}

@media screen and (max-width: 374px) {
  .footer {
    background-image: url("galaxy-background--375.c63a950c.jpg");
  }
}

.footer__column {
  margin-bottom: 3.125rem;
}

@media screen and (min-width: 769px) {
  .footer__column {
    margin-bottom: 0;
  }
}

.footer__container {
  color: #fff;
  text-align: center;
  padding: 3.125rem 1.875rem;
}

@media screen and (min-width: 769px) {
  .footer__container {
    text-align: left;
  }
}

.footer__link {
  color: #fff;
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 2.22;
  transition: color .3s;
}

.footer__link:hover {
  color: #fff9;
}

.footer__logo {
  width: 2.625rem;
}

.footer__title {
  color: #fff;
  margin-bottom: 1.4375rem;
  font-size: 1.375rem;
}

.footer .column:first-child {
  display: inline-flex;
}

.footer p + p {
  margin-top: 1em;
}

.card {
  background: #fff;
  border-radius: .625rem;
  padding: 1.875rem;
  box-shadow: 0 .125rem .25rem #d6d6d680;
}

@media screen and (min-width: 769px) {
  .card {
    padding: 2.5rem 4.6875rem;
  }
}

.card .columns {
  --columnGap: 2rem;
}

@media screen and (min-width: 1408px) {
  .card .columns {
    --columnGap: 3.5rem;
  }
}

.card .title {
  margin-bottom: 2.1875rem;
}

.catchphrase {
  color: #262a6e;
  text-align: center;
  margin-bottom: 1.5625rem;
  font-size: 1.625rem;
}

@media screen and (min-width: 769px) {
  .catchphrase {
    margin: 2rem 0 .9375rem;
  }
}

@media screen and (max-width: 768px) {
  .catchphrase br {
    content: "";
    margin-bottom: 1.25rem;
    display: block;
  }
}

.catchphrase.-light {
  color: #545454;
  font-weight: 300;
}

.code {
  color: #fff;
  white-space: pre-line;
  background: #121212;
  border-radius: .25rem;
  padding: 3.75rem 2.5rem;
}

.code__step {
  color: #ffc800;
  margin: 2rem 0 1rem;
  font-weight: bold;
  display: block;
}

.code__title {
  font-style: italic;
  text-decoration: underline;
  display: block;
}

@media screen and (max-width: 768px) {
  .code {
    padding: 3.75rem 1.0625rem;
  }
}

.compatibleServices {
  margin-top: 3.75rem;
}

@media screen and (min-width: 769px) {
  .compatibleServices {
    margin-top: 2.1875rem;
  }
}

.compatibleServices .column {
  padding: 1.25rem;
}

.compatibleServices__item {
  border: 0 solid #0000;
  border-bottom-width: .1875rem;
  transition: border-color .3s;
}

.compatibleServices__item img {
  filter: grayscale();
  max-width: 80%;
  max-height: 5rem;
  transition: filter .3s, transform 1s;
}

.compatibleServices__item:hover img {
  filter: grayscale(0);
  transform: translateY(-.625rem);
}

.compatibleServices .slider__slide img {
  height: 4.75rem;
}

@media screen and (min-width: 769px) {
  .contributing {
    padding-bottom: 5.625rem;
  }
}

.contributing__block {
  background-image: url("contribute-background.60f432b0.jpg");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}

@media screen and (max-width: 1599px) {
  .contributing__block {
    background-image: url("contribute-background--1600.2d349db9.jpg");
  }
}

@media screen and (max-width: 1365px) {
  .contributing__block {
    background-image: url("contribute-background--1366.8bcc8b88.jpg");
  }
}

@media screen and (max-width: 1023px) {
  .contributing__block {
    background-image: url("contribute-background--1024.597ca6cc.jpg");
  }
}

@media screen and (max-width: 767px) {
  .contributing__block {
    background-image: url("contribute-background--768.69db7948.jpg");
  }
}

@media screen and (max-width: 639px) {
  .contributing__block {
    background-image: url("contribute-background--640.8d60f736.jpg");
  }
}

@media screen and (max-width: 374px) {
  .contributing__block {
    background-image: url("contribute-background--375.98591cfb.jpg");
  }
}

.contributing__content {
  flex-direction: column;
  align-items: center;
  max-width: 60rem;
  max-height: 17rem;
  margin: 0 auto;
  padding: 2.1875rem .9375rem;
  display: flex;
}

@media screen and (min-width: 769px) {
  .contributing__content {
    flex-direction: row;
    height: 7.875rem;
  }
}

.contributing__text {
  color: #fff;
  text-align: center;
  flex-grow: 1;
  margin-bottom: 1.875rem;
  font-size: 1.625rem;
  font-weight: 300;
}

@media screen and (min-width: 769px) {
  .contributing__text {
    margin: initial;
    text-align: left;
  }
}

.cta {
  color: #fff;
  text-align: center;
  cursor: pointer;
  background-color: #541d62;
  border: 0;
  border-radius: 12.5rem;
  width: 100%;
  max-width: 19.6875rem;
  min-height: 3.125rem;
  margin: 0 auto;
  padding: .5625rem 2.875rem;
  font-size: 1.625rem;
  line-height: 1.9375rem;
  transition: background-color .3s, color .3s, box-shadow .3s;
  display: block;
}

@media screen and (min-width: 769px) {
  .cta {
    max-width: initial;
    margin: initial;
    width: auto;
    display: inline-block;
  }
}

@media screen and (max-width: 768px) {
  .cta.-mt {
    margin-top: 3.125rem;
  }
}

.cta:focus {
  outline: 0;
  box-shadow: 0 0 .625rem .125rem #ff32bb;
}

.cta:hover {
  color: #fff;
  background-color: #ff32bb;
}

.cta.-inverse:not(:hover) {
  color: #541d62;
  background-color: #fff;
}

.cta.-small {
  font-size: 1.375rem;
}

.cta.-on-pink:focus {
  box-shadow: 0 0 .625rem .125rem #541d62;
}

.cta.-on-pink:hover {
  background-color: #541d62;
}

.header {
  margin: 2.375rem .625rem;
}

@media screen and (min-width: 769px) {
  .header {
    margin: 2.1875rem .625rem;
  }
}

.header__branding {
  flex-direction: column;
  align-items: center;
  margin: auto;
  display: flex;
}

@media screen and (min-width: 769px) {
  .header__branding {
    flex-direction: row;
    margin: 0;
  }
}

.header__brandingLogo {
  width: 2.6875rem;
  height: 2.3125rem;
  margin: 0 0 .875rem;
}

@media screen and (min-width: 769px) {
  .header__brandingLogo {
    width: 4.4375rem;
    height: 3.8125rem;
    margin: 0 1.375rem 0 0;
  }
}

.header__brandingNameAuthor {
  flex-direction: column;
  align-items: flex-end;
  display: flex;
}

@media screen and (min-width: 769px) {
  .header__brandingNameAuthor {
    flex-direction: row;
  }
}

.header__brandingName {
  width: 3.1875rem;
  height: 1.25rem;
  margin: 0 0 .1875rem;
}

@media screen and (min-width: 769px) {
  .header__brandingName {
    width: 5.5625rem;
    height: 2rem;
    margin: 0 .75rem 0 0;
  }
}

.header__brandingAuthor {
  color: #fff;
  font-size: .625rem;
  font-weight: 300;
}

@media screen and (min-width: 769px) {
  .header__brandingAuthor {
    font-size: 1rem;
  }
}

.header__button {
  display: none;
}

.header__button:checked ~ .header__navigation {
  transform: translateX(0);
}

.header__button:checked ~ .header__icon {
  position: fixed;
}

.header__button:checked ~ .header__icon .header__iconLines {
  background: none;
}

.header__button:checked ~ .header__icon .header__iconLines:before, .header__button:checked ~ .header__icon .header__iconLines:after {
  top: 0;
}

.header__button:checked ~ .header__icon .header__iconLines:before {
  transform: rotate(45deg);
}

.header__button:checked ~ .header__icon .header__iconLines:after {
  transform: rotate(-45deg);
}

.header__icon {
  z-index: 900;
  color: #fff;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  margin: 0;
  padding: .5rem 0;
  position: absolute;
  top: 1.875rem;
  left: 1.875rem;
}

@media screen and (min-width: 769px) {
  .header__icon {
    display: none;
  }
}

.header__iconLines {
  width: 1.5rem;
  height: .125rem;
  transition: background-color .2s ease-out;
  position: relative;
}

.header__iconLines:before, .header__iconLines:after {
  content: "";
  width: 100%;
  height: 100%;
  transition: all .2s ease-out;
  position: absolute;
}

.header__iconLines, .header__iconLines:before, .header__iconLines:after {
  background-color: #fff;
  display: block;
}

.header__iconLines:before {
  top: -.5rem;
}

.header__iconLines:after {
  top: .5rem;
}

.header__container {
  justify-content: space-between;
  align-items: center;
  height: 3.75rem;
  display: flex;
}

.header__navigation {
  display: flex;
}

.header__navigationItem {
  margin: 0 3.125rem 0 0;
}

.header__navigationItem:last-child {
  margin: 0;
}

.header__navigationLink {
  color: #fff;
  letter-spacing: .0775rem;
  text-transform: uppercase;
  border: 0 solid #0000;
  border-bottom-width: .125rem;
  font-size: 1rem;
  font-weight: 400;
  text-decoration: none;
  transition: color .3s, border-color .3s;
}

.header__navigationLink svg {
  width: 2.25rem;
  height: 2.0625rem;
  transition: opacity .3s;
}

.header__navigationLink:focus {
  border-color: #ff32bb;
  outline: 0;
}

.header__navigationLink:hover {
  color: #fff9;
  text-decoration: none;
}

.header__navigationLink:hover svg {
  opacity: .6;
}

.header__navigationList {
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.header__navigation.-isSidebar {
  z-index: 30;
  background: #1d1344e6;
  justify-content: center;
  align-items: center;
  min-width: 16.25rem;
  transition: transform .3s;
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-150vw);
}

.header__navigation.-isSidebar .header__navigationItem {
  margin: 0 0 3.125rem;
}

.header__navigation.-isSidebar .header__navigationItem:last-child {
  margin: 0;
}

.header__navigation.-isSidebar .header__navigationLink {
  font-size: 1.25rem;
}

.header__navigation.-isSidebar .header__navigationLink svg {
  width: 2.8125rem;
  height: 2.625rem;
}

.header__navigation.-isSidebar .header__navigationList {
  flex-direction: column;
}

@media screen and (min-width: 769px) {
  .introduction__columns {
    flex-direction: row-reverse;
  }
}

.introduction__description {
  color: #fff;
  text-align: center;
  margin-bottom: 1.5625rem;
  font-size: 1.75rem;
  font-weight: 300;
  line-height: 1.54;
}

@media screen and (min-width: 769px) {
  .introduction__description {
    text-align: left;
    margin-bottom: 4.0625rem;
  }
}

.introduction__image {
  width: 100%;
  max-width: 28.125rem;
}

@media screen and (min-width: 769px) {
  .introduction__image {
    max-width: 38.875rem;
  }
}

.introduction__title {
  color: #fff;
  text-align: center;
  margin-bottom: 2.5rem;
  font-size: 2.5rem;
  line-height: 1.25;
}

@media screen and (min-width: 769px) {
  .introduction__title {
    text-align: left;
    margin-bottom: 1.5625rem;
  }
}

.item {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.item__description {
  text-align: center;
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 1.75rem;
}

.item__icon {
  align-items: flex-end;
  height: 3.875rem;
  margin-bottom: 1.25rem;
  display: flex;
}

.item__icon.-shape {
  width: 4.125rem;
}

.item__icon.-social {
  width: 2.9375rem;
}

.item__icon.-puzzle {
  width: 4.3125rem;
}

.item__title {
  color: #1d1344;
  text-align: center;
  margin-bottom: 2.1875rem;
  font-size: 1.5rem;
  line-height: 1.8125rem;
}

@media screen and (min-width: 769px) {
  .item__title {
    margin-bottom: 1.5625rem;
    font-size: 2rem;
  }
}

.link {
  color: #545454;
  border-bottom: .125rem solid #ff32bb;
  text-decoration: none;
  transition: color .3s;
}

.link:hover {
  color: #ff32bb;
}

.list {
  margin: 1.5625rem 0;
}

.list__item {
  align-items: center;
  margin-bottom: 2.5rem;
  display: flex;
}

@media screen and (min-width: 769px) {
  .list__item {
    margin-bottom: 3.125rem;
  }
}

.list__item:last-child {
  margin: 0;
}

.list__itemIcon {
  flex: 0 0 1.125rem;
  width: 1.125rem;
  height: 1.125rem;
  margin-right: 1.375rem;
}

.list__itemText {
  color: #757575;
  font-size: 1.375rem;
  font-weight: 300;
}

.schema {
  justify-content: center;
  margin-top: 3.75rem;
  display: flex;
  position: relative;
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .schema {
    transform: scale(.9);
  }
}

.schema__analysisContainer {
  margin-bottom: 1rem;
  display: flex;
}

.schema__analysisContainer:last-child {
  margin: 0;
}

.schema__analysisContainer > * {
  margin-left: 1rem;
  margin-right: 1rem;
}

.schema__arrow {
  position: relative;
}

@media screen and (min-width: 1024px) {
  .schema__arrow {
    margin: 1.25rem 0;
  }
}

@media screen and (max-width: 1023px) {
  .schema__arrow {
    left: -.8125rem;
  }
}

.schema__arrowNumber {
  color: #083060;
  font-size: 1.875rem;
  font-weight: 700;
  position: absolute;
  left: calc(100% - .25rem);
  transform: translateX(-50%);
}

@media screen and (min-width: 1024px) {
  .schema__arrowNumber {
    top: -.75rem;
    left: calc(50% + .25rem);
  }
}

.schema__column {
  border: 0 dashed #d6d6d680;
  border-top-width: .125rem;
}

@media screen and (min-width: 1024px) {
  .schema__column {
    border-width: 0 0 0 .125rem;
    padding: 1.875rem 0 4.6875rem;
  }
}

.schema__column:last-child {
  border-width: .125rem 0;
}

@media screen and (min-width: 1024px) {
  .schema__column:last-child {
    border-width: 0 .125rem;
  }
}

.schema__column.-isStep {
  width: 18.75rem;
  padding-left: .4375rem;
  padding-right: .4375rem;
  position: relative;
}

@media screen and (max-width: 1023px) {
  .schema__column.-isStep > :not(.schema__arrow) {
    position: absolute;
  }
}

.schema__column.-isStep1 .schema__info {
  top: 1.5625rem;
}

.schema__column.-isStep2 .schema__info, .schema__column.-isStep3 .schema__info {
  bottom: 1.5625rem;
}

.schema__column.-isStep4 .schema__info {
  top: 1.5625rem;
}

.schema__column.-isStep2 .schema__arrowNumber, .schema__column.-isStep3 .schema__arrowNumber {
  top: 4.5625rem;
}

.schema__column.-isStep1 .schema__arrowNumber, .schema__column.-isStep4 .schema__arrowNumber {
  bottom: 4.75rem;
}

.schema__columns {
  flex-direction: column;
  display: flex;
}

.schema__columns > * {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media screen and (min-width: 1024px) {
  .schema__columns {
    flex-direction: row;
  }
}

.schema__info {
  color: #083060;
  text-align: center;
  background-color: #f7f7f7;
  border: .0625rem dotted #d6d6d6;
  border-radius: 1.5rem;
  align-items: center;
  height: 4.375rem;
  padding: .625rem 1.25rem;
  font-size: .875rem;
  font-style: italic;
  line-height: 1.0625rem;
  display: flex;
}

.schema__listeners {
  display: flex;
}

@media screen and (min-width: 1024px) {
  .schema__listeners {
    position: absolute;
    bottom: .625rem;
  }
}

.schema__listeners > * {
  margin-left: .625rem;
  margin-right: .625rem;
}

.schema__square {
  color: #083060;
  background-color: #fff;
  border-radius: 1.25rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 5rem;
  height: 5rem;
  font-size: 1.25rem;
  font-weight: 700;
  display: flex;
  box-shadow: 0 .25rem .5rem #ababab80;
}

@media screen and (min-width: 1024px) {
  .schema__square {
    margin-top: .625rem;
    margin-bottom: .625rem;
  }
}

.schema__square img {
  max-width: 3.25rem;
  max-height: 3.25rem;
}

.schema__square.-big {
  width: 9.5rem;
  height: 9.5rem;
}

.schema__square.-tool {
  background: linear-gradient(#8d3e9b, #541d62);
  width: 12.75rem;
  height: 12.75rem;
}

.schema__square.-tool svg:first-child {
  width: 5.875rem;
  height: 5.0625rem;
}

.schema__square.-tool svg:last-child {
  width: 4.875rem;
  height: 1.75rem;
  margin-top: .625rem;
}

.slider.-displayDots .slider__dots {
  display: block;
}

.slider__dots {
  margin-top: 3.75rem;
  display: none;
}

.slider__dotsWrap {
  justify-content: center;
  list-style: none;
  display: flex;
}

.slider__dotsItem {
  cursor: pointer;
  -webkit-appearance: none;
  background-color: #d8d8d8;
  border: 0;
  border-radius: 50%;
  outline: 0;
  justify-content: center;
  align-items: center;
  width: .75rem;
  height: .75rem;
  margin: 0 .3125rem;
  padding: 0;
  text-decoration: none;
  transition: background-color .3s, box-shadow .6s;
  display: inline-flex;
  position: relative;
}

.slider__dotsItem.-isActive {
  background-color: #262a6e;
}

.slider__slide {
  scroll-snap-align: start;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-right: 3.125rem;
  display: flex;
  position: relative;
}

.slider__track {
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  display: flex;
  overflow-x: auto;
}

.slider__track::-webkit-scrollbar {
  display: none;
}

.title {
  flex-direction: column;
  align-items: center;
  display: flex;
}

@media screen and (min-width: 769px) {
  .title {
    flex-direction: row;
    justify-content: center;
  }
}

.title__icon {
  width: 3rem;
  height: 1.875rem;
  margin: 0 0 1.25rem;
  display: block;
}

@media screen and (min-width: 769px) {
  .title__icon {
    margin: 0 1.375rem 0 0;
  }
}

.title__text {
  color: #262a6e;
  font-size: 1.625rem;
}

@media screen and (min-width: 769px) {
  .title__text {
    font-size: 2rem;
  }
}

html {
  scroll-behavior: smooth;
  background-image: url("background-purple.a8d62ed8.jpg");
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100% 75.625rem;
}

@media screen and (max-width: 1599px) {
  html {
    background-image: url("background-purple--1600.c6155b55.jpg");
  }
}

@media screen and (max-width: 1365px) {
  html {
    background-image: url("background-purple--1366.511843b4.jpg");
  }
}

@media screen and (max-width: 1023px) {
  html {
    background-image: url("background-purple--1024.364596bd.jpg");
  }
}

@media screen and (max-width: 767px) {
  html {
    background-image: url("background-purple--768.5ba34ef2.jpg");
  }
}

@media screen and (max-width: 639px) {
  html {
    background-image: url("background-purple--640.51f5d791.jpg");
  }
}

@media screen and (max-width: 374px) {
  html {
    background-image: url("background-purple--375.50b06fd7.jpg");
  }
}

@media screen and (min-width: 769px) {
  html {
    background-size: 100% 47.8125rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  html {
    font-size: 90%;
  }
}

:focus {
  outline-color: #ff32bb;
}

/*# sourceMappingURL=index.a3201e52.css.map */
