.schema {
  $root: &;

  position: relative;
  display: flex;
  justify-content: center;
  margin-top: rem-from-px(60);

  @include desktop-only() {
    transform: scale(0.9);
  }

  &__analysisContainer {
    display: flex;
    margin-bottom: rem-from-px(16);

    &:last-child {
      margin: 0;
    }

    > * {
      margin-right: rem-from-px(16);
      margin-left: rem-from-px(16);
    }
  }

  &__arrow {
    $arrow: &;

    position: relative;

    @include desktop {
      margin: rem-from-px(20) 0;
    }

    @include touch {
      left: rem-from-px(-13);
    }

    &Number {
      position: absolute;
      left: calc(100% - #{rem-from-px(4)});
      color: $color-madison;
      font-weight: $weight-bold;
      font-size: rem-from-px(30);
      transform: translateX(-50%);

      @include desktop {
        top: rem-from-px(-12);
        left: calc(50% + #{rem-from-px(4)});
      }
    }
  }

  &__column {
    border-color: rgba($grey-lighter, 0.5);
    border-style: dashed;
    border-width: rem-from-px(2) 0 0 0;

    @include desktop {
      padding: rem-from-px(30) 0 rem-from-px(75) 0;
      border-width: 0 0 0 rem-from-px(2);
    }

    &:last-child {
      border-width: rem-from-px(2) 0;

      @include desktop {
        border-width: 0 rem-from-px(2);
      }
    }

    &.-isStep {
      position: relative;
      width: rem-from-px(300);
      padding-right: rem-from-px(7);
      padding-left: rem-from-px(7);

      @include touch {
        > *:not(#{$root}__arrow) {
          position: absolute;
        }
      }

      &1 {
        #{$root}__info {
          top: rem-from-px(25);
        }
      }

      &2 {
        #{$root}__info {
          bottom: rem-from-px(25);
        }
      }

      &3 {
        #{$root}__info {
          bottom: rem-from-px(25);
        }
      }

      &4 {
        #{$root}__info {
          top: rem-from-px(25);
        }
      }

      &2,
      &3 {
        #{$root}__arrowNumber {
          top: rem-from-px(73);
        }
      }

      &1,
      &4 {
        #{$root}__arrowNumber {
          bottom: rem-from-px(76);
        }
      }
    }
  }

  &__columns {
    display: flex;
    flex-direction: column;

    > * {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    @include desktop {
      flex-direction: row;
    }
  }

  &__info {
    display: flex;
    align-items: center;
    height: rem-from-px(70);
    padding: rem-from-px(10) rem-from-px(20);
    color: $color-madison;
    font-size: rem-from-px(14);
    font-style: italic;
    line-height: rem-from-px(17);
    text-align: center;
    background-color: $grey-lightest;
    border: rem-from-px(1) dotted $grey-lighter;
    border-radius: rem-from-px(24);
  }

  &__listeners {
    display: flex;

    @include desktop {
      position: absolute;
      bottom: rem-from-px(10);
    }

    > * {
      margin-right: rem-from-px(10);
      margin-left: rem-from-px(10);
    }
  }

  &__square {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: rem-from-px(80);
    height: rem-from-px(80);
    color: $color-madison;
    font-weight: $weight-bold;
    font-size: rem-from-px(20);
    background-color: $white;
    border-radius: rem-from-px(20);
    box-shadow: 0 rem-from-px(4) rem-from-px(8) 0 rgba($grey-light, 0.5);

    @include desktop {
      margin-top: rem-from-px(10);
      margin-bottom: rem-from-px(10);
    }

    img {
      max-height: rem-from-px(52);
      max-width: rem-from-px(52);
    }

    &.-big {
      width: rem-from-px(152);
      height: rem-from-px(152);
    }

    &.-tool {
      width: rem-from-px(204);
      height: rem-from-px(204);
      background: $gradient-alpha;

      svg {
        &:first-child {
          width: rem-from-px(94);
          height: rem-from-px(81);
        }

        &:last-child {
          width: rem-from-px(78);
          height: rem-from-px(28);
          margin-top: rem-from-px(10);
        }
      }
    }
  }
}
