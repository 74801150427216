.item {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__description {
    font-weight: $weight-light;
    font-size: rem-from-px(18);
    line-height: rem-from-px(28);
    text-align: center;
  }

  &__icon {
    display: flex;
    align-items: flex-end;
    height: rem-from-px(62);
    margin-bottom: rem-from-px(20);

    &.-shape {
      width: rem-from-px(66);
    }

    &.-social {
      width: rem-from-px(47);
    }

    &.-puzzle {
      width: rem-from-px(69);
    }
  }

  &__title {
    margin-bottom: rem-from-px(35);
    color: $color-haiti;
    font-size: rem-from-px(24);
    line-height: rem-from-px(29);
    text-align: center;

    @include from($tablet) {
      margin-bottom: rem-from-px(25);
      font-size: rem-from-px(32);
    }
  }
}
