.introduction {
  &__columns {
    @include from($tablet) {
      flex-direction: row-reverse;
    }
  }

  &__description {
    margin-bottom: rem-from-px(25);
    color: $white;
    font-weight: $weight-light;
    font-size: rem-from-px(28);
    line-height: 1.54;
    text-align: center;

    @include from($tablet) {
      margin-bottom: rem-from-px(65);
      text-align: left;
    }
  }

  &__image {
    width: 100%;
    max-width: rem-from-px(450);

    @include from($tablet) {
      max-width: rem-from-px(622);
    }
  }

  &__title {
    margin-bottom: rem-from-px(40);
    color: $white;
    font-size: rem-from-px(40);
    line-height: 1.25;
    text-align: center;

    @include from($tablet) {
      margin-bottom: rem-from-px(25);
      text-align: left;
    }
  }
}
