.code {
  padding: rem-from-px(60) rem-from-px(40);
  color: $white;
  white-space: pre-line;
  background: $black-bis;
  border-radius: rem-from-px(4);

  &__step {
    display: block;
    margin: rem-from-px(32) 0 rem-from-px(16);
    color: $color-supernova;
    font-weight: bold;
  }

  &__title {
    display: block;
    font-style: italic;
    text-decoration: underline;
  }

  @include mobile {
    padding: rem-from-px(60) rem-from-px(17);
  }
}
