@mixin font-face($name, $path, $weight: null, $style: null) {
  $src: null;
  $exts: woff2 woff;

  @each $ext in $exts {
    $src: append($src, url(quote($path + "." + $ext)) format(quote($ext)), comma);
  }

  @font-face {
    font-display: swap;
    font-family: quote($name);
    font-style: $style;
    font-weight: $weight;
    src: $src;
  }
}

@include font-face("Source Sans Pro", "../assets/fonts/source-sans-pro/light", 300, normal);
@include font-face("Source Sans Pro", "../assets/fonts/source-sans-pro/regular", 400, normal);
