@import "globals/background";
@import "globals/color";
@import "globals/font";
@import "globals/icon";
@import "globals/layout";
@import "globals/vendor";
@import "components/footer";
@import "components/card";
@import "components/catchphrase";
@import "components/code";
@import "components/compatible-services";
@import "components/contributing";
@import "components/cta";
@import "components/header";
@import "components/introduction";
@import "components/item";
@import "components/link";
@import "components/list";
@import "components/schema";
@import "components/slider";
@import "components/title";

html {
  scroll-behavior: smooth;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 100% rem-from-px(1210);

  @include background-responsive(
    "../assets/images/background-purple",
    "jpg",
    (1600, 1366, 1024, 768, 640, 375)
  );

  @include from($tablet) {
    background-size: 100% rem-from-px(765);
  }

  @include tablet-only {
    font-size: 90%;
  }
}

*:focus {
  outline-color: $color-razzle-rose;
}
